import React from 'react';

import { boolean, func, object, string } from 'prop-types';

import { Icon } from 'paymongo-ui';

import './Icon.scss';

const CmsIcon = ({
  className,
  icon,
  id,
  onBlur,
  onClick,
  onFocus,
  onMouseEnter,
  onMouseLeave,
  onMouseOut,
  onMouseOver,
  pulse,
  size,
  spin,
  style
}) => {
  return (
    <div className="cms-icon">
      <Icon
        className={className}
        icon={icon}
        id={id}
        onBlur={onBlur}
        onClick={onClick}
        onFocus={onFocus}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseOut={onMouseOut}
        onMouseOver={onMouseOver}
        pulse={pulse}
        size={size}
        spin={spin}
        style={style}
      />
    </div>
  );
};

CmsIcon.propTypes = {
  className: string,
  icon: string.isRequired,
  id: string,
  onBlur: func,
  onClick: func,
  onFocus: func,
  onMouseEnter: func,
  onMouseLeave: func,
  onMouseOut: func,
  onMouseOver: func,
  pulse: boolean,
  size: string,
  spin: boolean,
  style: object
};

CmsIcon.defaultProps = {
  className: undefined,
  id: undefined,
  onBlur: undefined,
  onClick: undefined,
  onFocus: undefined,
  onMouseEnter: undefined,
  onMouseLeave: undefined,
  onMouseOut: undefined,
  onMouseOver: undefined,
  pulse: false,
  size: 'default',
  spin: false,
  style: undefined
};

export default CmsIcon;

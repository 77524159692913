import React from 'react';

import { string } from 'prop-types';

import './Button.scss';

const Button = ({ buttonLabel, className, destination }) => (
  <a href={destination} className={`button ${className}`}>
    {buttonLabel}
  </a>
);

Button.propTypes = {
  buttonLabel: string,
  className: string,
  destination: string
};

Button.defaultProps = {
  buttonLabel: 'Button',
  className: ``,
  destination: `/`
};

export default Button;

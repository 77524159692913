import React from 'react';

import { arrayOf, shape, string } from 'prop-types';

import { Link } from 'gatsby';

import paymongoLogoG from '@images/paymongo-logo-green.png';
import paymongoLogoW from '@images/paymongo-logo-white.png';

import './DesktopHeader.scss';

const DesktopHeader = ({ title, linksArray }) => {
  const links = linksArray.map((item, index) => (
    <li key={`links-array-item-${index + 1}`}>
      <Link to={item.path} className="nav-link" key={`nav-link-${index + 1}`}>
        {item.name}
      </Link>
    </li>
  ));

  return (
    <header className="desktop-header display-none-is-mobile">
      <div className="logo-container">
        <Link to="/" className="paymongo-logo">
          <img alt={title} src={paymongoLogoG} className="logo-green" />
          <img alt={title} src={paymongoLogoW} className="logo-white" />
        </Link>
      </div>
      <ul>
        {links}
        <li>
          <a className="nav-link dashboard-cta" href={process.env.GATSBY_LINK_TO_LOGIN}>
            Log in
          </a>
        </li>
      </ul>
    </header>
  );
};

DesktopHeader.propTypes = {
  title: string.isRequired,
  linksArray: arrayOf(
    shape({
      name: string,
      path: string
    })
  ).isRequired
};

export default DesktopHeader;

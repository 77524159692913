import React from 'react';

import { bool, func, string } from 'prop-types';

import HubspotForm from 'react-hubspot-form';
import { NotificationManager } from 'paymongo-ui';

import PaymongoElement from '../paymongo_element/PaymongoElement';

import './HubspotFormModal.scss';

const HubspotFormModal = ({ formId, header, visible, onClose, portalId, successMessage }) => {
  const notificationProps = {
    transition: 'slide',
    position: 'bottom left',
  };

  const submittedCallback = () => {
    onClose();
    if (typeof window !== 'undefined') {
      NotificationManager.push('success', <div>{successMessage}</div>, 5000);
    }
  };

  return (
    <>
      <PaymongoElement name="Modal" props={{ visible, onClose }}>
        {header && (
          <PaymongoElement type="header" name="ModalSection">
            {header}
          </PaymongoElement>
        )}
        <PaymongoElement type="body" name="ModalSection">
          {visible && (
            <HubspotForm
              portalId={portalId}
              formId={formId}
              onSubmit={() => {}}
              onFormSubmitted={submittedCallback}
              loading={(
                <div className="hubspot-loading-container">
                  <PaymongoElement
                    name="LoadingSpinner"
                    props={{
                      visible: true,
                      color: 'brand'
                    }}
                  />
                </div>
              )}
            />
          )}
        </PaymongoElement>
      </PaymongoElement>
      <PaymongoElement name="Notification" props={notificationProps} />
    </>
  );
};

HubspotFormModal.propTypes = {
  header: string,
  formId: string.isRequired,
  visible: bool.isRequired,
  onClose: func.isRequired,
  portalId: string.isRequired,
  successMessage: string.isRequired
};

HubspotFormModal.defaultProps = {
  header: undefined
};

export default HubspotFormModal;

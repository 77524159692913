import React from 'react';

import { string } from 'prop-types';

import './Blockquote.scss';

const Blockquote = ({ text }) => {
  return (
    <blockquote>
      <p>{text}</p>
    </blockquote>
  );
};

Blockquote.propTypes = {
  text: string
};

Blockquote.defaultProps = {
  text: ``
};

export default Blockquote;

import React, { useState } from 'react';

import { arrayOf, shape, string } from 'prop-types';

import { Link } from 'gatsby';

import paymongoLogoG from '@images/paymongo-logo-green.png';
import paymongoLogoW from '@images/paymongo-logo-white.png';

import PaymongoElement from '../../../paymongo_element/PaymongoElement';
import MobileDropdown from './components/mobile_dropdown/MobileDropdown';

import './MobileHeader.scss';

const MobileHeader = ({ title, linksArray }) => {
  const [active, setActive] = useState(false);

  const toggleActive = () => {
    const { classList } = document.querySelector('body');
    if (!active) {
      classList.add('menu-active');
    } else {
      classList.remove('menu-active');
    }

    setActive(!active);
  };

  return (
    <>
      <header className="mobile-header display-none-from-tablet">
        <div className="open-button-container">
          <PaymongoElement
            name="Button"
            props={{ className: 'open-button', onClick: toggleActive }}
          >
            <PaymongoElement name="Icon" props={{ icon: 'hamburgerMenu' }} />
          </PaymongoElement>
        </div>
        <div className="logo-container">
          <Link to="/" className="paymongo-logo">
            <img alt={title} src={paymongoLogoG} className="logo-green"/>
            <img alt={title} src={paymongoLogoW} className="logo-white"/>
          </Link>
        </div>
      </header>
      <MobileDropdown
        active={active}
        title={title}
        linksArray={linksArray}
        toggleActive={toggleActive}
      />
    </>
  );
};

MobileHeader.propTypes = {
  title: string.isRequired,
  linksArray: arrayOf(
    shape({
      name: string,
      path: string
    })
  ).isRequired
};

export default MobileHeader;

import React from 'react';

import { arrayOf, bool, shape, string, func } from 'prop-types';

import { Link } from 'gatsby';
import { Button, Icon } from 'paymongo-ui';

import ClassNames from 'classnames';

import paymongoLogo from '@images/paymongo-logo.png';

import Portal from '../../../../../portal/Portal';

import './MobileDropdown.scss';

const MobileDropdown = ({ active, title, linksArray, toggleActive }) => {
  const links = linksArray.map((item, index) => (
    <li className="link-container" key={`nav-link-${index + 1}`}>
      <Link onClick={toggleActive} to={item.path} className="nav-link">
        {item.name}
      </Link>
    </li>
  ));

  const classNames = ClassNames(['mobile-dropdown', active && 'active']);

  return (
    <Portal className={classNames}>
      <div className="close-button-container">
        {typeof window !== 'undefined' && (
          <Button className="close-button" onClick={toggleActive}>
            <Icon icon="times" size="sm" />
          </Button>
        )}
      </div>
      <div className="mobile-dropdown-content">
        <div className="logo-container">
          <Link to="/" className="paymongo-logo">
            <img alt={title} src={paymongoLogo} />
          </Link>
        </div>
        <ul className="links-wrapper">
          <li>
            <a className="nav-link sign-up-cta" href={process.env.GATSBY_LINK_TO_SIGNUP}>
              Get started for free
            </a>
          </li>
          {links}
          <li>
            <a className="nav-link dashboard-cta" href={process.env.GATSBY_LINK_TO_LOGIN}>
              Log in to Dashboard
            </a>
          </li>
        </ul>
      </div>
    </Portal>
  );
};

MobileDropdown.propTypes = {
  active: bool.isRequired,
  title: string.isRequired,
  linksArray: arrayOf(
    shape({
      name: string,
      path: string
    })
  ).isRequired,
  toggleActive: func.isRequired
};

export default MobileDropdown;

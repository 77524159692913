import React from 'react';

import { node, string, shape } from 'prop-types';

import './PageSection.scss';

import ClassNames from 'classnames';

const PageSection = ({ className, children, id, style }) => {
  const classNames = ClassNames(['page-section', className]);

  return (
    <section className={classNames} id={id} style={style}>
      <div className="container">{children}</div>
    </section>
  );
};

PageSection.propTypes = {
  children: node.isRequired,
  className: string,
  id: string,
  style: shape({})
};

PageSection.defaultProps = {
  className: '',
  id: undefined,
  style: {}
};

export default PageSection;

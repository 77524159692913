import React from 'react';
import { shape } from 'prop-types';

import './SemisphereBackground.scss';

const SemisphereBackground = ({ style }) => <div className="semisphere-background" style={style} />;

SemisphereBackground.propTypes = {
  style: shape({})
};

SemisphereBackground.defaultProps = {
  style: {}
};

export default SemisphereBackground;

/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';

import { node, string } from 'prop-types';

import { useStaticQuery, graphql } from 'gatsby';

import { Header, Footer } from '@components';

import './BaseTemplate.scss';

const BaseTemplate = ({ children, className }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <div className="paymongodotcom-class-container">
      <Header siteTitle={data.site.siteMetadata.title} className={className} />
      <main className="base-content">{children}</main>
      <Footer />
    </div>
  );
};

BaseTemplate.propTypes = {
  children: node.isRequired,
  className: string
};

BaseTemplate.defaultProps = {
  className: ``
};

export default BaseTemplate;

import React from 'react';

import { string } from 'prop-types';

import { useStaticQuery, graphql } from 'gatsby';

import DesktopHeader from './components/desktop_header/DesktopHeader';
import MobileHeader from './components/mobile_header/MobileHeader';

import './Header.scss';

const Header = ({ className }) => {
  const data = useStaticQuery(graphql`
    query SiteQuery {
      site {
        siteMetadata {
          title
          navLinks {
            name
            path
          }
        }
      }
    }
  `);

  const { navLinks, title } = data.site.siteMetadata;
  const classNames = `header ${className}`;

  return (
    <header className={classNames}>
      <MobileHeader title={title} linksArray={navLinks} />
      <DesktopHeader title={title} linksArray={navLinks} />
    </header>
  );
};

Header.propTypes = {
  className: string
};

Header.defaultProps = {
  className: ``
};

export default Header;

import React from 'react';
import { Link } from 'gatsby';
import './Footer.scss';

import footerLogo from '@images/paymongo-logo-white.png';

const Footer = () => {
  const footerLinks = [
    {
      label: 'Home',
      destination: '/'
    },
    {
      label: 'Pricing',
      destination: '/pricing'
    },
    {
      label: 'Developers',
      destination: '/developers'
    },
    {
      label: 'Terms of Use',
      destination: '/terms'
    },
    {
      label: 'Privacy Policy',
      destination: '/privacy'
    },
    {
      label: 'Help Center',
      destination: process.env.GATSBY_LINK_TO_HELPCENTER
    }
  ];

  const footerLinkItems = footerLinks.map((item, index) => (
    <li key={`footer-link-${index + 1}`} className="column is-narrow-desktop">
      {index + 1 < footerLinks.length ? (
        <a href={item.destination} key={`selling-point-item-${index + 1}`}>
          {item.label}
        </a>
      ) : (
        <a href={item.destination} key={`selling-point-item-${index + 1}`}>
          {item.label}
        </a>
      )}
    </li>
  ));

  return (
    <footer className="footer">
      <div className="container">
        <div className="columns is-multiline is-vcentered">
          <div className="column">
            <ul className="columns link-list">{footerLinkItems}</ul>
          </div>
          <div className="column other-info">
            <p className="address-line text-right-from-tablet">
              Unit 3308, High St South Corp Plaza Tower 2
              <br />
              26th St and 11th Ave, Bonifacio Global City
              <br />
              Taguig 1634 Philippines
            </p>
            <div className="columns is-multiline copyright">
              <div className="column is-narrow logo">
                <Link to="/">
                  <img src={footerLogo} alt="PayMongo PH" />
                </Link>
              </div>
              <div className="column is-narrow copyright">
                <p className="my-none text-xs">© PayMongo 2020</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

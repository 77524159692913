import React from 'react';

import { arrayOf, node, oneOfType, shape, string } from 'prop-types';

import { Button, Icon, LoadingSpinner, Modal, ModalSection, Notification } from 'paymongo-ui';
import ClassNames from 'classnames';

import './PaymongoElement.scss';

const elementsObj =
  typeof window !== 'undefined'
    ? {
        Button,
        Icon,
        LoadingSpinner,
        Modal,
        ModalSection,
        Notification
      }
    : null;

const PaymongoElement = ({ children, name, props }) => {
  const Element =
    elementsObj !== null && elementsObj.hasOwnProperty(name) ? elementsObj[name] : null;
  const classNames = ClassNames(['paymongo-element', props.className]);

  return Element !== null ? (
    <Element {...props} className={classNames}>
      {children}
    </Element>
  ) : null;
};

PaymongoElement.propTypes = {
  children: oneOfType([arrayOf(node), node]),
  name: string.isRequired,
  props: shape({})
};

PaymongoElement.defaultProps = {
  children: undefined,
  props: {}
};

export default PaymongoElement;

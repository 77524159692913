/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from 'react';
import { arrayOf, shape, string } from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';
import favicon from '@images/favicon.ico';
import ogimg from '@images/og-image-fb.jpg';

function SEO({ description, lang, meta, title, image }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const metaDescription = description || site.siteMetadata.description;

  const siteTitle = title || site.siteMetadata.title;

  return (
    <Helmet
      htmlAttributes={{
        lang
      }}
      title={siteTitle}
      titleTemplate={`%s | ${site.siteMetadata.title}`}
      meta={[
        {
          name: `description`,
          content: metaDescription
        },
        {
          property: `og:title`,
          content: `${siteTitle} | ${site.siteMetadata.title}`
        },
        {
          property: `og:description`,
          content: metaDescription
        },
        {
          property: `og:image`,
          content: image || process.env.GATSBY_SITE_URL + ogimg
        },
        {
          property: `og:type`,
          content: `website`
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`
        },
        {
          name: `twitter:image`,
          content: image || process.env.GATSBY_SITE_URL + ogimg
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.author
        },
        {
          name: `twitter:title`,
          content: `${siteTitle} | ${site.siteMetadata.title}`
        },
        {
          name: `twitter:description`,
          content: metaDescription
        }
      ].concat(meta)}
      link={[
        { rel: 'icon', type: 'image/png', sizes: '16x16', href: `${favicon}` },
        { rel: 'icon', type: 'image/png', sizes: '32x32', href: `${favicon}` },
        { rel: 'shortcut icon', type: 'image/png', href: `${favicon}` }
      ]}
    />
  );
}

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``
};

SEO.propTypes = {
  description: string,
  lang: string,
  meta: arrayOf(shape({})),
  title: string.isRequired
};

export default SEO;

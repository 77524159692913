import React, { useState } from 'react';
import { string, bool, func, shape } from 'prop-types';
import ClassNames from 'classnames';

import './Toggle.scss';

const Toggle = props => {
  const { checked, style, id, className, disabled, onChange, defaultChecked } = props;

  const [stateChecked, setStateChecked] = useState(defaultChecked);

  const clickHandler = () => {
    const checkedValue = !(typeof checked !== 'undefined' ? checked : stateChecked);
    setStateChecked(checkedValue);
    if (typeof onChange !== 'undefined') {
      onChange(checkedValue);
    }
  };

  const checkedValue = typeof checked !== 'undefined' ? checked : stateChecked;

  const toggleChecked = checkedValue ? 'toggle-checked' : '';

  const classNames = ClassNames('custom-button', 'toggle-button', className, toggleChecked);

  const onLabelClass = ClassNames('onLabel', checkedValue ? 'checked' : '');
  const offLabelClass = ClassNames('offLabel', checkedValue ? '' : 'unchecked');

  return (
    <button
      style={style}
      id={id}
      className={classNames}
      disabled={disabled}
      onChange={onChange}
      onClick={clickHandler}
      type="button"
    >
      <span>
        <span className={onLabelClass}>YES</span>
        <span className={offLabelClass}>NO</span>
      </span>
    </button>
  );
};

Toggle.propTypes = {
  checked: bool,
  style: shape({}),
  id: string,
  className: string,
  disabled: bool,
  onChange: func.isRequired,
  defaultChecked: bool
};

Toggle.defaultProps = {
  checked: undefined,
  style: {},
  id: undefined,
  className: '',
  disabled: false,
  defaultChecked: false
};

export default Toggle;
